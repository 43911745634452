@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');



@font-face {
  font-family: 'Inter';
  src: url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
  font-weight: normal;
  font-style: normal;
  font-size: 14;
} 

.maincontainer{
  padding:0 1em !important;
  width: auto;
  max-width: 100%;
  
}
.gridSubContainer{
  padding-top: 0px !important;
}
.containerPaper {
  background-color: #232D3B;
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background-color: #2A3545;
}
.custom-scrollbar::-webkit-scrollbar-corner{
  background-color: #2A3545;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height:8px;
  background-color: #2A3545;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #D9D9D9;
}


.wave {
  width: 3px;
  height: 40px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

